export default [
  {
    title: "Home",
    route: "home",
    icon: "HomeIcon",
  },

  {
    title: "courses",
    icon: "FileTextIcon",
    tagVariant: "light-warning",
    route: "list-courses",
  },
  // {
  //   title: "About us",
  //   icon: "UsersIcon",
  //   tagVariant: "light-warning",
  // },
  // {
  //   title: "Contact us",
  //   icon: "FileTextIcon",
  //   tagVariant: "light-warning",
  // },

  // {
  //   title: "Answers",
  //   icon: "CheckSquareIcon",

  //   tagVariant: "light-warning",
  //   children: [
  //     {
  //       title: "list All Answers",
  //       route: "list-answers",
  //     },
  //   ],
  // },
];
